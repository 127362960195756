import { createStyles, Theme, WithStyles, withStyles } from '@signatu/styles';
import { Link } from 'gatsby';
import React from 'react';

interface HeaderProps {
  title: string
}

const styles = (theme: Theme) => createStyles({
  root: {
    // height: "120px", 
    padding: theme.spacing.unit * 2, 
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }, 
  link: {
    fontSize: "1.5rem",
    textDecoration: 'none',
    fontWeight: 600,
    color: `${theme.palette.primary.contrastText} !important`, 
    '&:hover': {
      textDecoration: 'none'
    }, 
    '&:focus': {
      textDecoration: 'none'
    }
  }
})

const HeaderInner = (props: any) => props.children

const Header: React.FunctionComponent<HeaderProps & WithStyles<typeof styles>> = ({ classes, title }) => (
  <div className={classes.root}>
    <HeaderInner>
      <Link className={classes.link} to="/">{title}</Link>
    </HeaderInner>
  </div>
)

export default withStyles(styles)(Header)
