// import { FullpageProgress } from '@signatu/common-mui';
import { createStyles, Theme, WithStyles, withStyles } from '@signatu/styles';
import React, { FunctionComponent } from 'react';
import IndexLayout from '../../layouts/IndexLayout';

import Root from '../../../../home-server/dist/ui/root'
import rootStore from '../../../../home-server/dist/redux/rootStore';
import { StaticRouter } from 'react-router';

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  content: {
    overflow: "scroll"
  }
})

interface IndexPageProps {
  location: Location
}

const IndexPage: FunctionComponent<WithStyles<typeof styles> & IndexPageProps> = ({location, classes}) => (
  <IndexLayout>
    <StaticRouter location={location} >
      <Root store={rootStore} loggedIn={false}/>
    </StaticRouter>
  </IndexLayout>
)

export default withStyles(styles)(IndexPage)
