import React from 'react'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts/IndexLayout'
import { FunctionComponent } from 'react';
import links from "../data/links"
import StickyResponsiveSidebar from '../components/sidebar/StickyResponsiveSidebar';
import { Theme, createStyles, WithStyles, withStyles } from '@signatu/styles';

interface DocTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
      }
    }
  }, 
  location: Location
}

const styles = (theme: Theme) => createStyles({
  docContainer: {
    display: "flex",
    flexDirection: "row"
  }, 
  content: {
    overflow: "auto", 
    
  }
})

const DocTemplate: FunctionComponent<DocTemplateProps & WithStyles<typeof styles>> = ({ data, classes, location }) => (
  <IndexLayout>
    <Page>
      <Container className={classes.docContainer}>
        <StickyResponsiveSidebar
            enableScrollSync={true}
            itemList={links()}
            location={location}
        />
        <Container className={classes.content}>
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </Container>
      </Container>
    </Page>
  </IndexLayout>
)

export default withStyles(styles)(DocTemplate)

export const query = graphql`
  query DocTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
      }
    }
  }
`
