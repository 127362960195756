module.exports = [{
      plugin: require('/Users/torgeir/dev/signatu/signatu/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/torgeir/dev/signatu/signatu/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://docs.signatu.com/"},
    },{
      plugin: require('/Users/torgeir/dev/signatu/signatu/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/torgeir/dev/signatu/signatu/packages/docs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
