import { createStyles, Theme, WithStyles, withStyles } from '@signatu/styles';
import React, { FunctionComponent } from 'react';
import { RedocStandalone as Redoc } from 'redoc';

import IndexLayout from '../../layouts/IndexLayout';

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  content: {
    overflow: "scroll"
  }

})

interface IndexPageProps {
  location: Location
  spec: any
}

const IndexPage: FunctionComponent<WithStyles<typeof styles> & IndexPageProps> = ({location, spec, classes}) => {
  return (
    <IndexLayout>
      <Redoc
        spec={spec}
        options={{
          nativeScrollbars: true
          // theme: { colors: { primary: { main: '#dd5522' } } },
        }}
      />  
    </IndexLayout>    
  )
}

export default withStyles(styles)(IndexPage)
