import { createStyles, Theme } from "@signatu/styles";

const bulletSize = 8

export default (theme: Theme) => createStyles({
    draft: {
      "&&": {
        color: theme.palette.grey[500],
      },
    },
    parentOfActiveLink: {
      "&&": {
        color: theme.palette.secondary.main,
        fontWeight: 600,
      },
    },
    activeLink: {
      "&&": {
        /** @todo fix this */
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: 600,
        background: theme.palette.grey[100], 
      },
      "&:before": {
        background: theme.palette.secondary.main,
        transform: `scale(1)`,
      },
      "&:after": {
        opacity: 1,
        width: 100
      },
  
    },
    link: {
      paddingRight: theme.spacing.unit,
      // minHeight: presets.itemMinHeight,
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      position: `relative`,
      zIndex: 1,
      textDecoration: 'none',
      width: `100%`,
      "&&": {
        border: 0,
        color: theme.palette.grey[500],
        fontWeight: `normal`,
        "&:hover": {
          background: theme.palette.grey[50],
          color: theme.palette.secondary.main,
          "&:before": {
            background: theme.palette.secondary.main,
            transform: `scale(1)`,
          },
        },
      },
      "&:before, &:after": {
        left: `-${theme.spacing.unit * 2 }px`,
          // level === 0 || level === 1
          //   ? `calc(${indention} - 16px)`
          //   : `calc(${indention} - 24px)`,
        top: `1em`,
        height: bulletSize,
        position: `absolute`,
        transition: `all 200ms`
      },
      "&:before": {
        borderRadius: theme.spacing.unit,
        content: `''`,
        transform: `scale(0.5)`,
        width: bulletSize,
      },
      "&:after": {
        background: theme.palette.secondary.main,
        borderRadius: theme.spacing.unit / 4,
        content: `''`,
        left: `calc(${theme.spacing.unit} - 8px)`,
        //   level === 0 || level === 1
        //     ? `calc(${indention} - 8px)`
        //     : `calc(${indention} - 16px)`,
        opacity: 0,
        transform: `translateX(-100px)`,
        width: 1,
      },
    },
    subsectionLink: {
      left: theme.spacing.unit,
      // background: colors.white,
      border: `1px solid ${theme.palette.secondary.light}`,
      // borderRadius: radii[6],
      display: `block`,
      fontWeight: `normal`,
      height: bulletSize,
      position: `absolute`,
      width: bulletSize,
      top: `1.3em`,
      zIndex: -1,
    },
    linkContainer: {
      display: `flex`,
      alignItems: `center`,
      position: `relative`,
      "&:before": {
        background: theme.palette.grey[50],
        bottom: 0,
        top: `auto`,
        content: `''`,
        height: 1,
        position: `absolute`,
        right: 0,
        // left: indention,
      },
    }
})
