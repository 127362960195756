import React from 'react';

import Accordion from './Accordion';
import MenuLink from './MenuLink';

const isItemActive = (activeItemParents, item) => {
  if (activeItemParents) {
    for (const parent of activeItemParents) {
      if (parent === item.title) {
        return true
      }
    }
  }

  return false
}

interface ItemProps {
  className?: string
  activeItemLink: string
  activeItemParents: string[]
  openSectionHash: any
  item: any
  location: Location
  onLinkClick: (...args) => any
  onSectionTitleClick: (...args) => any
  ui?: any
  isSingle?: boolean
  isActive?: boolean
  isExpanded?: boolean
}

class Item extends React.PureComponent<ItemProps> {
  render() {
    const {
      activeItemLink,
      activeItemParents,
      isActive,
      openSectionHash,
      item,
      location,
      onLinkClick,
      onSectionTitleClick,
      ui,
      isSingle,
    } = this.props

    const isParentOfActiveItem = isItemActive(activeItemParents, item)

    return (
      <>
        {item.items ? (
          <Accordion
            activeItemLink={activeItemLink}
            activeItemParents={activeItemParents}
            isActive={
              isActive ||
              item.link === location.pathname ||
              isParentOfActiveItem ||
              item.disableAccordions
            }
            isParentOfActiveItem={isParentOfActiveItem}
            item={item}
            location={location}
            onLinkClick={onLinkClick}
            openSectionHash={openSectionHash}
            onSectionTitleClick={onSectionTitleClick}
            isSingle={isSingle}
          />
        ) : (
          <li 
          // css={this.props.styles}
          >
            <MenuLink
              isActive={item.link === activeItemLink.link}
              item={item}
              location={location}
              onLinkClick={onLinkClick}
              stepsUI={ui === `steps`}
              level={item.level}
              // indention={indention(item.level)}
            />
          </li>
        )}
      </>
    )
  }
}

export default Item
