import React from 'react';
import { FunctionComponent } from 'react';

interface ContainerProps {
  className?: string
}

const Container: FunctionComponent<ContainerProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
)

export default Container
