import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Signatu } from '@signatu/icons';
export const _frontmatter = {
  "layout": "Doc",
  "title": "Active MDX Test"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Hello, `}<em parentName="h1">{`world`}</em>{`!`}</h1>
    <p>{`Below is an example of JSX embedded in Markdown. `}<br />{` `}</p>
    <p><strong parentName="p">{`Try and change the background color!`}</strong></p>
    <Signatu mdxType="Signatu" />
    <div style={{
      padding: '20px',
      backgroundColor: 'blue'
    }}>
  <h3>This is JSX</h3>
  {new Date().toString()}
    </div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      