import React from 'react'
import { Theme, createStyles, withStyles, WithStyles } from '@signatu/styles';
import { FunctionComponent } from 'react';
import '@signatu/css/css/fonts.css'

interface LayoutRootProps {
  className?: string
}

const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: theme.typography.fontFamily, 
    fontSize: 16,
    fontWeight: 400, 
    lineHeight: "1.5em",
    '& a': {
      color: theme.palette.secondary.light, 
      textDecoration: 'none'
    }
  }
})

const LayoutRoot: FunctionComponent<LayoutRootProps & WithStyles<typeof styles>> = ({ children, classes }) => (
  <>
    <div className={classes.root}>{children}</div>
  </>
)

export default withStyles(styles)(LayoutRoot)
