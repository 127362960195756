import { WithStyles, withStyles } from '@signatu/styles';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { FunctionComponent } from 'react';

import styles from './MenuLink.styles';

const getTitle = (title, isD) => (isD ? title.slice(0, -1) : title)
const isDraft = title => title.slice(-1) === `*`

interface MenuLinkProps {
  item: any,
  onLinkClick: (...args) => any,
  isActive?: boolean
  isExpanded?: boolean
  isParentOfActiveItem?: boolean
  stepsUI?: any,
  customCSS?: any
  level?: number,
  indention?: number,
  location?: Location
}

const MenuLink: FunctionComponent<MenuLinkProps & WithStyles<typeof styles>> = ({
  classes,
  item,
  isActive, 
  isExpanded,
  isParentOfActiveItem = false,
  onLinkClick,
  stepsUI = false,
  indention = 0
}) => {
  const draft = isDraft(item.title)
  const title = getTitle(item.title, draft)

  return (
    <span className={classes.linkContainer} >
    <Link 
      style={{paddingLeft: indention}}
      className={classNames(isActive && classes.activeLink, isDraft && classes.draft, isParentOfActiveItem && classes.parentOfActiveLink, classes.link)}
      onClick={onLinkClick}
      to={item.link}
    >
        {stepsUI && <span className={classes.subsectionLink} />}
        {title}
    </Link>
  </span>
  )
}

export default withStyles(styles)(MenuLink)
