/** Styles for syntax highlighting */
require("prismjs/themes/prism.css")

const React = require('react')
const ReactJss = require('react-jss')
const Styles = require("@signatu/styles"); 

exports.onInitialClientRender = () => {
  var ssStyles = window.document.getElementById("server-side-jss");
  if (ssStyles) {
    ssStyles.parentNode.removeChild(ssStyles);
  }
}; // eslint-disable-next-line react/prop-types


exports.wrapRootElement = (ref, options) => {
  const element = ref.element;

  return React.createElement(ReactJss.JssProvider, {
    registry: new ReactJss.SheetsRegistry()
  }, React.createElement(Styles.ThemeProvider, {
    theme: Styles.createTheme(), 
    sheetsManager: new Map()
  }, element));
};