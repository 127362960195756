import React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@signatu/styles';
import { FunctionComponent } from 'react';

const styles = (theme: Theme) => createStyles({
  root: {
    display: "block",
    flex: 1, 
    position: "relative", 
    padding: theme.spacing.unit * 2, 
    marginBottom: "3rem"  
  }
})

interface PageProps {
  className?: string
}

const Page: FunctionComponent<PageProps & WithStyles<typeof styles>> = ({ children, className }) => <div className={className}>{children}</div>

export default withStyles(styles)(Page)
