// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-doc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/templates/Doc.tsx" /* webpackChunkName: "component---src-templates-doc-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-apidoc-tsx" */),
  "component---src-pages-api-v-0-consent-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v0/consent/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-0-consent-apidoc-tsx" */),
  "component---src-pages-api-v-0-dataprocessing-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v0/dataprocessing/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-0-dataprocessing-apidoc-tsx" */),
  "component---src-pages-api-v-0-oauth-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v0/oauth/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-0-oauth-apidoc-tsx" */),
  "component---src-pages-api-v-0-webhooks-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v0/webhooks/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-0-webhooks-apidoc-tsx" */),
  "component---src-pages-api-v-1-consent-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v1/consent/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-1-consent-apidoc-tsx" */),
  "component---src-pages-api-v-1-dataprocessing-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v1/dataprocessing/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-1-dataprocessing-apidoc-tsx" */),
  "component---src-pages-api-v-1-oauth-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v1/oauth/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-1-oauth-apidoc-tsx" */),
  "component---src-pages-api-v-1-webhooks-apidoc-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/api/v1/webhooks/apidoc.tsx" /* webpackChunkName: "component---src-pages-api-v-1-webhooks-apidoc-tsx" */),
  "component---src-pages-app-index-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-active-mdx": () => import("/Users/torgeir/dev/signatu/signatu/packages/docs/src/pages/active.mdx" /* webpackChunkName: "component---src-pages-active-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/torgeir/dev/signatu/signatu/packages/docs/.cache/data.json")

