import React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@signatu/styles';
import classNames from 'classnames'

const styles = (theme: Theme) => createStyles({
  root: {
    margin: theme.spacing.unit * 2
  }
})
interface LayoutMainProps {
  className?: string
}

const LayoutMain: React.FunctionComponent<LayoutMainProps & WithStyles<typeof styles>> = ({ children, className, classes }) => (
  <div className={classNames(className, classes.root)}>{children}</div>
)

export default withStyles(styles)(LayoutMain)
