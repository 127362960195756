import React from "react"
import classNames from 'classnames'
import ChevronSvg from "./chevron-svg"
import { createStyles, Theme, withStyles } from "@signatu/styles";
import MenuLink from "./MenuLink";

const styles = (theme: Theme) => createStyles({
  resetButton: {
    backgroundColor: `transparent`,
    border: 0,
    cursor: `pointer`,
    padding: 0,
  },
  button: {
    position: `relative`,
    textAlign: `left`,
    width: `100%`,
  },
  titleButton: {
    // paddingLeft: item.level === 0 ? space[6] : 0,
    paddingRight: `0 !important`,
    minHeight: 40,
    "&:before": {
      // ...styles.ulHorizontalDivider,
      bottom: 0,
      // left: item.level === 0 ? space[6] : 0,
      top: `auto`,
    },
  },
  titleButtonText: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    right: 0,
    // minHeight: presets.itemMinHeight,
    // width: presets.itemMinHeight,
  },
  sectionTitle: {
      alignItems: `center`,
      display: `flex`,
      // fontSize: scale[1],
      // fontWeight: isActive ? `bold` : `normal`,
      margin: 0,
      // ...(item.level === 0 && { ...styles.smallCaps }),
      // color: isExpanded ? colors.gatsby : false,
      // "&:hover": {
      //   color: disabled ? false : colors.gatsby,
      // },
  },
  ulHorizontalDivider: {
    // background: presets.itemBorderColor,
    top: 0,
    content: `''`,
    height: 1,
    position: `absolute`,
    right: 0,
    left: 24,
  },
  smallCaps: {
    // fontFamily: fonts.header,
    // letterSpacing: letterSpacings.tracked,
    textTransform: `uppercase`,
  },
  chevron: {
    alignItems: `center`,
    display: `flex`,
      flexShrink: 0,
      marginLeft: `auto`,
      height: `100%`,
      width: `100%`,
      // minHeight: presets.itemMinHeight,
      // minWidth: presets.itemMinHeight,
      "&:before": {
        // ...styles.ulHorizontalDivider,
        bottom: 0,
        left: `0 !important`,
        top: `auto`,
      },
      "&:hover": {
        // background: presets.activeSectionBackground,
      },
  }, 
  chevronSvg: {
      // color: isExpanded ? colors.gray.light : colors.gray.light,
      marginLeft: `auto`,
      marginRight: `auto`,
      transform: `rotateX(0deg)`,
      transition: `transform 200ms`  
  }, 
  title: {
    alignItems: `center`,
    display: `flex`,
    // paddingLeft: indention(item.level),
    minHeight: 40,
  }, 
  splitButtonContainer: {
    alignItems: `flex-end`,
    display: `flex`,
    position: `relative`,
    width: `100%`,
  }, 
  splitButtonInner: {
    flexGrow: 1,
    // borderRight: `1px solid ${presets.itemBorderColor}`,
  }, 
  splitButton: {
    background: 'none',
    marginLeft: `auto`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    right: 0,
    border: "none", 
    color: theme.palette.grey[500],
    zIndex: 1,
    '&:active': {
      border: 'none'
    }
  }, 
  expanded: {
    transform: `rotateX(180deg)`
  }
})

const Chevron = ({ isExpanded, classes }) => (
  <span className={classes.chevron}>
    <ChevronSvg className={classNames(classes.chevronSvg, isExpanded && classes.expanded)} />
  </span>
)

const StyledChevron = withStyles(styles)(Chevron)

const TitleButton = ({
  classes,
  isActive,
  isExpanded,
  item,
  onSectionTitleClick,
  uid
}) => (
  <button
    aria-expanded={isExpanded}
    aria-controls={uid}
    className={classNames(classes.resetButton, classes.button, classes.titleButton)}
    onClick={() => onSectionTitleClick(item)}
  >
    <StyledSectionTitle isExpanded={isExpanded} isActive={isActive} item={item}>
      {item.title}
      <span className={classes.titleButtonText}>
        <StyledChevron isExpanded={isExpanded} />
      </span>
    </StyledSectionTitle>
  </button>
)

const StyledTitleButton = withStyles(styles)(TitleButton)

const SplitButton = ({
  classes,
  isActive,
  isExpanded,
  isParentOfActiveItem,
  item,
  location,
  onLinkClick,
  onSectionTitleClick,
  uid,
}) => (
  <span className={classNames(classes.splitButtonContainer, classes.resetButton)}>
    <span className={classes.splitButtonInner}>
    <MenuLink 
        isActive={isActive}
        isExpanded={isExpanded}
        isParentOfActiveItem={isParentOfActiveItem}
        item={item}
        location={location}
        onLinkClick={onLinkClick}
        // level: item.level,
        // indention: indention(item.level),
        // customCSS: {
        //   ...(item.level === 0 && {
        //     "&&": {
        //       // ...styles.smallCaps,
        //       // color: isExpanded ? colors.gatsby : false,
        //       fontWeight: isActive ? `bold` : `normal`,
        //     },
        //   }),
          // paddingRight: presets.itemMinHeight,
    />
    </span>
    <button
      className={classNames(classes.splitButton, isExpanded && classes.expanded)}
      aria-controls={uid}
      aria-expanded={isExpanded}
      aria-label={item.title + (isExpanded ? ` collapse` : ` expand`)}

      onClick={() => onSectionTitleClick(item)}
    >
      <StyledChevron isExpanded={isExpanded} />
    </button>
  </span>
)

const StyledSplitButton = withStyles(styles)(SplitButton)

const Title = ({ item, isActive, isExpanded, classes }) => (
  <div className={classes.title}>
    <StyledSectionTitle
      disabled={true}
      isActive={isActive}
      isExpanded={isExpanded}
      item={item}
    >
      {item.title}
    </StyledSectionTitle>
  </div>
)

const StyledTitle = withStyles(styles)(Title)

const SectionTitle = ({ children, classes, isExpanded, isActive, disabled = false, item }) => (
  <h3 className={classes.sectionTitle}>
    {children}
  </h3>
)
const StyledSectionTitle = withStyles(styles)(SectionTitle)

export { StyledTitle as Title, StyledTitleButton as TitleButton, StyledSplitButton as SplitButton }
