  export default (version = 'v0') => (
  [
    {
      "title": "API Documentation", 
      "items": [
        {
          "title": "Overview", 
          "link": `/api/${version}/overview`,
          "items": [
            {
              "title": "URI Formats", 
              "link": `/api/${version}/uriformats`  
            }
          ]
        },
        {
          "title": `Consent API (${version})`, 
          "link": `/api/${version}/consent/overview`, 
          "items": [
            {
              "title": "Endpoint", 
              "link": `/api/${version}/consent/endpoint`
            }, 
            {
              "title": "Authorization", 
              "link": `/api/${version}/consent/authorization`
            },
            {
              "title": "Methods", 
              "link": `/api/${version}/consent/consent`
            },
            {
              "title": "Fields", 
              "link": `/api/${version}/consent/fields`
            }, 
            {
              "title": "Consent JWT Receipt", 
              "link": `/api/${version}/consent/receipt`
            }, 
            {
              "title": "API Reference", 
              "link": `/api/${version}/consent/apidoc`
            }
          ]
        }, 
        {
          "title": `OAuth API (${version})`, 
          "link": `/api/${version}/oauth/overview`,
          "items": [
            {
              "title": "API Reference", 
              "link": `/api/${version}/oauth/apidoc`
            }
          ]
        }, 
        {
          "title": `Data Processing API (${version})`, 
          "link": `/api/${version}/dataprocessing/overview`,
          "items": [
            {
              "title": "API Reference", 
              "link": `/api/${version}/dataprocessing/apidoc`
            }
          ]
        }, 
        {
          "title": `Webhooks (${version})`, 
          "link": `/api/${version}/webhooks/overview`,
          "items": [
            {
              "title": "API Reference", 
              "link": `/api/${version}/webhooks/apidoc`
            }
          ]
        }, 
      ]
    }
  ]
)
