import React, { Component } from "react"

import Item from "./Item"
import ExpandAllButton from "./ButtonExpandAll"
import getActiveItem from "./util/get-active-item"
import getActiveItemParents from "./util/get-active-item-parents"
import { createStyles, Theme, WithStyles, withStyles } from "@signatu/styles";

// Access to global `localStorage` property must be guarded as it
// fails under iOS private session mode.
let hasLocalStorage = true
const testKey = `gatsbyjs.sidebar.testKey`
let ls
try {
  ls = global['localStorage']
  ls.setItem(testKey, `test`)
  ls.removeItem(testKey)
} catch (e) {
  hasLocalStorage = false
}

const isItemActive = (activeItemParents, item) => {
  if (activeItemParents) {
    for (const parent of activeItemParents) {
      if (parent === item.title) {
        return true
      }
    }
  }

  return false
}

const getOpenItemHash = (itemList, state) => {
  for (const item of itemList) {
    if (item.items) {
      state.openSectionHash[item.title] =
        isItemActive(state.activeItemParents, item) ||
        state.activeItemLink.title === item.title

      getOpenItemHash(item.items, state)
    }
  }

  return false
}

const styles = (theme: Theme) => createStyles({
  utils: {
    // borderRight: `1px solid ${colors.gray.border}`,
    display: `flex`,
    alignItems: `center`,
    // height: dimensions.sidebarUtilityHeight,
    background: theme.palette.backgroundColor,
    paddingLeft: theme.spacing.unit,
    paddingRight: 8,
  },
  sidebarScrollContainer: {
    WebkitOverflowScrolling: `touch`,
    // background: presets.backgroundDefault,
    border: 0,
    display: `block`,
    overflowY: `auto`,
    // transition: `opacity ${transition.speed.slow} ${transition.curve.default}`,
    zIndex: 10,
    // borderRight: `1px solid ${colors.gray.border}`,
  },
  sidebarScrollContainerTablet: {
    // backgroundColor: presets.backgroundTablet,
    // top: `calc(${dimensions.headerHeight} + ${dimensions.bannerHeight})`,
  },
  list: {
    margin: 0,
    paddingTop: theme.spacing.unit * 4, 
    paddingBottom: theme.spacing.unit, 
    paddingLeft: theme.spacing.unit,
    // fontSize: scale[1],
    "& li": {
      margin: 0,
      listStyle: `none`,
    },
    "& > li:last-child > span:before": {
      display: `none`,
    },
  },
  section: {
    height: "100%"
  }
})

interface SidebarBodyProps {
  itemList: any[]
  position: number
  closeSidebar
  location: Location
  onPositionChange: (...args) => any
}

interface SidebarBodyState {
  expandAll: boolean
  openSectionHash: any
  key: any;
  activeItemHash: string
  activeItemLink: string
  activeItemParents: any;
}

class SidebarBody extends Component<SidebarBodyProps & WithStyles<typeof styles>, SidebarBodyState> {
  
  static getDerivedStateFromProps(props, state) {
    if (props.activeItemHash !== state.activeItemHash) {
      const activeItemLink = getActiveItem(
        props.itemList,
        props.location,
        props.activeItemHash
      )

      return {
        activeItemLink,
        activeItemParents: getActiveItemParents(
          props.itemList,
          activeItemLink,
          []
        ),
        activeItemHash: props.activeItemHash,
      }
    }

    return null
  }
  scrollRef: any
  constructor(props, context) {
    super(props, context)

    this.state = { ...this.getInitialState(props) }
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const node = this.scrollRef.current

    if (hasLocalStorage) {
      const key = this.props.itemList[0].key
      const initialState = this.state
      const localState = this.readLocalStorage(key)

      if (localState) {
        const bar = Object.keys(initialState.openSectionHash).filter(k => initialState.openSectionHash[k])
        const state = {
          ...initialState,
          openSectionHash: JSON.parse(localState).openSectionHash,
        }

        // tslint:disable-next-line:forin
        for (const item in initialState.openSectionHash) {
          for (const parent of bar) {
            if (parent === item) {
              state.openSectionHash[item] = true
            }
          }
        }

        state.expandAll = Object.entries(state.openSectionHash).every(k => k[1] as boolean)
        this.setState(state, () => {
          if (node && this.props.position) {
            node.scrollTop = this.props.position
          }
        })
      } else {
        this.writeLocalStorage(this.state, key)
      }
    }
  }

  getInitialState(props) {
    const activeItemLink = getActiveItem(
      props.itemList,
      props.location,
      props.activeItemHash
    )

    const state = {
      openSectionHash: {},
      expandAll: false,
      key: props.itemList[0].key,
      activeItemHash: props.activeItemHash,
      activeItemLink,
      activeItemParents: getActiveItemParents(
        props.itemList,
        activeItemLink,
        []
      ),
    }

    getOpenItemHash(props.itemList, state)
    state.expandAll = Object.entries(state.openSectionHash).every(k => k[1] as boolean)

    return state
  }

  readLocalStorage(key) {
    if (hasLocalStorage) {
      return localStorage.getItem(`signatu:sidebar:${key}`)
    } else {
      return false
    }
  }

  writeLocalStorage(state, key) {
    if (hasLocalStorage) {
      localStorage.setItem(`signatu:sidebar:${key}`, JSON.stringify(state))
    }
  }

  toggleSection = (item) => {
    const { openSectionHash } = this.state

    const state = {
      openSectionHash: {
        ...openSectionHash,
        [item.title]: !openSectionHash[item.title],
      },
      expandAll: false
    }

    state.expandAll = Object.entries(state.openSectionHash).every(k => k[1] as boolean)

    this.writeLocalStorage(state, this.state.key)
    this.setState(state)
  }

  expandAll = () => {
    if (this.state.expandAll) {
      this.writeLocalStorage(
        { openSectionHash: this.getInitialState(this.props).openSectionHash },
        this.state.key
      )
      this.setState({
        ...this.getInitialState(this.props),
        expandAll: false,
      })
    } else {
      const openSectionHash = { ...this.state.openSectionHash }
      Object.keys(openSectionHash).forEach(k => (openSectionHash[k] = true))
      this.writeLocalStorage({ openSectionHash }, this.state.key)
      this.setState({ openSectionHash, expandAll: true })
    }
  }

  render() {
    const { closeSidebar, itemList, location, onPositionChange, classes } = this.props
    const { openSectionHash, activeItemLink, activeItemParents } = this.state

    const isSingle = itemList.filter(item => item.level === 0).length === 1

    return (
      <section
        className={classes.section}
        aria-label="Secondary Navigation"
        id="SecondaryNavigation"
      >
        {!itemList[0].disableExpandAll && (
          <header className={classes.utils}>
            <ExpandAllButton
              onClick={this.expandAll}
              expandAll={this.state.expandAll}
            />
          </header>
        )}
        <nav
          onScroll={({ nativeEvent }) => {
            // get proper scroll position
            const position = nativeEvent.target && nativeEvent.target['scrollTop']
            const { pathname } = location
            const sidebarType = pathname.split(`/`)[1]

            requestAnimationFrame(() => {
              onPositionChange(sidebarType, position)
            })
          }}
          ref={this.scrollRef}
          className={classes.sidebarScrollContainer}
          // css={{
          //   height: itemList[0].disableExpandAll
          //     ? `100%`
          //     : `calc(100% - ${dimensions.sidebarUtilityHeight})`,
          //   [breakpoints.md]: {
          //     ...styles.sidebarScrollContainerTablet,
          //   },
          // }}
        >
          <ul className={classes.list}>
            {itemList.map((item, index) => (
              <Item
                activeItemLink={activeItemLink}
                activeItemParents={activeItemParents}
                isActive={openSectionHash[item.title]}
                item={item}
                key={index}
                location={location}
                onLinkClick={closeSidebar}
                onSectionTitleClick={this.toggleSection}
                openSectionHash={openSectionHash}
                isSingle={isSingle}
              />
            ))}
          </ul>
        </nav>
      </section>
    )
  }
}

export default withStyles(styles)(SidebarBody as any)
