import React, { Component } from "react"

import Sidebar from "./Sidebar"
import ScrollSyncSidebar from "./ScrollSyncSidebar"
import ChevronSvg from "./chevron-svg"

import ScrollPositionProvider, {
  ScrollPositionConsumer,
} from "./ScrollbarPositionProvider"
import { Theme, createStyles, withStyles, WithStyles } from "@signatu/styles";

const styles = (theme: Theme) => createStyles({
  sidebarScrollContainer: {
    border: 0,
    bottom: 0,
    display: `block`,
    height: `100vh`,
    marginRight: theme.spacing.unit * 2,
    // position: `fixed`,
    top: 0,
    // transition: `opacity ${transition.speed.slow} ${transition.curve.default}`,
    width: 320,
    zIndex: 10,
    // [breakpoints.md]: {
    //   height: `calc(100vh - ${dimensions.headerHeight} - ${
    //     dimensions.bannerHeight
    //   })`,
    //   maxWidth: `none`,
    //   opacity: `1 !important`,
    //   pointerEvents: `auto`,
    //   top: `calc(${dimensions.headerHeight} + ${dimensions.bannerHeight})`,
    //   width: rhythm(dimensions.sidebarWidth.default),
    // },
    // [breakpoints.lg]: {
    //   width: rhythm(dimensions.sidebarWidth.large),
    // },
  },
  sidebar: {
    height: `100%`,
    // transition: `transform ${transition.speed.slow} ${
    //   transition.curve.default
    // }`,
    // boxShadow: shadows.dialog,
    // [breakpoints.md]: {
    //   transform: `none !important`,
    //   boxShadow: `none`,
    // },
  },
  sidebarToggleButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: `50%`,
    // bottom: space[11],
    // boxShadow: shadows.dialog,
    cursor: `pointer`,
    display: `flex`,
    // height: space[10],
    justifyContent: `space-around`,
    position: `fixed`,
    // right: space[6],
    visibility: `visible`,
    // width: space[10],
    zIndex: 20,
    // [breakpoints.md]: { display: `none` },
  },
  sidebarToggleButtonInner: {
    alignSelf: `center`,
    // color: colors.white,
    display: `flex`,
    flexDirection: `column`,
    // height: space[5],
    visibility: `visible`,
    // width: space[5],
  },
})

interface StickyResponsiveSidebarState {
  open: boolean
}

interface StickyResponsiveSidebarProps {
  enableScrollSync: boolean
  itemList: any[]
  location: { pathname: string }
}

class StickyResponsiveSidebar extends Component<StickyResponsiveSidebarProps & WithStyles<typeof styles>, StickyResponsiveSidebarState> {
  constructor(props) {
    super(props)

    this.state = { open: false }
  }

  openSidebar = () => {
    this.setState({ open: !this.state.open })
  }

  closeSidebar = () => {
    this.setState({ open: false })
  }

  render() {
    const { open } = this.state
    const {
      enableScrollSync,
      location: { pathname },
      classes
    } = this.props
    const SidebarComponent = enableScrollSync ? ScrollSyncSidebar : Sidebar

    const iconOffset = open ? 5 : -5
    // const menuOpacity = open ? 1 : 0
    const menuOffset = open ? 0 : 10

    const sidebarType = pathname.split(`/`)[1]

    return (
      <ScrollPositionProvider>
        <div className={classes.sidebarScrollContainer}>
        {/* // css={{
          //   opacity: menuOpacity,
          //   pointerEvents: open ? `auto` : `none`,
          // }} */}
          <div className={classes.sidebar} style={{transform: `translateX(-${menuOffset})`}}>

            <ScrollPositionConsumer>
              {({ positions, onPositionChange }) => (
                <SidebarComponent
                  position={positions[sidebarType]}
                  onPositionChange={onPositionChange}
                  closeSidebar={this.closeSidebar}
                  {...this.props}
                />
              )}
            </ScrollPositionConsumer>
          </div>
        <div
          className={classes.sidebarToggleButton}
          onClick={this.openSidebar}
          role="button"
          aria-label="Show Secondary Navigation"
          aria-controls="SecondaryNavigation"
          aria-expanded={open ? `true` : `false`}
          tabIndex={0}
        >
          <div className={classes.sidebarToggleButtonInner}>
            <ChevronSvg
              size={15}
              style={{
                transform: `translate(${iconOffset}px, 5px) rotate(90deg)`,
                // transition: `transform ${transition.speed.fast} ${
                //   transition.curve.default
                // }`,
              }}
            />
            <ChevronSvg
              size={15}
              style={{
                transform: `translate(${5 -
                  iconOffset}px, -5px) rotate(270deg)`,
                // transition: `transform ${transition.speed.fast} ${
                //   transition.curve.default
                // }`,
              }}
            />
          </div>
        </div>
        </div>
      </ScrollPositionProvider>
    )
  }
}

export default withStyles(styles)(StickyResponsiveSidebar)
