
import React from "react"
import classNames from 'classnames'
import Item from "./Item"
import { Title, TitleButton, SplitButton } from "./SectionTitle"
import { createStyles, Theme, WithStyles, withStyles } from "@signatu/styles";

const ItemWithSubitems = ({
  activeItemLink,
  isExpanded,
  isParentOfActiveItem,
  activeItemParents, 
  item,
  location,
  onLinkClick,
  onSectionTitleClick,
  uid,
  isActive
}) => {
  const SectionTitleComponent: React.ComponentType<any> = item.disableAccordions ? Title : TitleButton
  const active = isActive || item.link === activeItemLink.link

  return (
    <>
      {item.link ? (
        <SplitButton
          isActive={active}
          isExpanded={isExpanded}
          isParentOfActiveItem={isParentOfActiveItem}
          item={item}
          location={location}
          onLinkClick={onLinkClick}
          onSectionTitleClick={onSectionTitleClick}
          uid={uid}
        />
      ) : (
        <SectionTitleComponent
          isActive={active}
          isExpanded={isExpanded}
          isParentOfActiveItem={isParentOfActiveItem}
          item={item}
          onSectionTitleClick={onSectionTitleClick}
          uid={uid}
        />
      )}
    </>
  )
}

const styles = (theme: Theme) => createStyles({
  root: {
      position: `relative`,
  },
  expanded: {
    // background: theme.palette.grey[50],
    marginTop: theme.spacing.unit / 2, 
    display: 'block',
    paddingBottom: theme.spacing.unit,
    borderBottom: `1px solid ${theme.palette.borderColor}`,
    marginBottom: theme.spacing.unit / 2, 
          //   paddingBottom:
          //     item.level === 0 && isExpanded && !isSingle ? space[6] : false,
          //   borderBottom:
          //     item.level === 0 && isExpanded && !isSingle
          //       ? `1px solid ${colors.gray.border}`
          //       : false,
          //   marginBottom:
          //     item.level === 0 && isExpanded && !isSingle
          //       ? `${space[6]} !important`
          //       : false,
          // }}

  }, 
  hide: {
    display: 'none',
  },
  ul: {
    listStyle: `none`,
    margin: 0,
    position: `relative`,
    paddingLeft: theme.spacing.unit
  },
  ulStepsUI: {
    "&:after": {
      background: theme.palette.primary.light,
      bottom: 0,
      content: `''`,
      left: 27,
      position: `absolute`,
      top: 0,
      width: 1,
    },
  },
})

interface AccordionProps {
  onLinkClick: (...args) => any
  onSectionTitleClick: (...args) => any

  activeItemLink: string
  activeItemParents: string[]
  isActive: boolean
  isParentOfActiveItem: boolean
  item: any
  location: Location
  openSectionHash: string
  isSingle: boolean
}

interface AccordionState {
  uid: string
}

class Accordion extends React.Component<AccordionProps & WithStyles<typeof styles>, AccordionState> {
  constructor(props) {
    super(props)

    this.state = {
      uid: (`` + Math.random()).replace(/\D/g, ``),
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(...args) {
    if (this.props.onLinkClick) {
      this.props.onLinkClick(...args)
    }

    if (this.props.onSectionTitleClick) {
      this.props.onSectionTitleClick(...args)
    }
  }

  render() {
    const {
      classes,
      activeItemLink,
      activeItemParents,
      isActive,
      isParentOfActiveItem,
      item,
      location,
      onLinkClick,
      onSectionTitleClick,
      openSectionHash,
      isSingle,
    } = this.props
    const uid = `item_` + this.state.uid
    const isExpanded = openSectionHash[item.title] || item.disableAccordions || false

    return (
      <li className={classNames(classes.root, isExpanded && classes.expanded)}>
        <ItemWithSubitems
          activeItemLink={activeItemLink}
          activeItemParents={activeItemParents}
          isActive={isActive}
          isExpanded={isExpanded}
          isParentOfActiveItem={isParentOfActiveItem}
          item={item}
          location={location}
          onLinkClick={onLinkClick}
          onSectionTitleClick={onSectionTitleClick}
          uid={uid}
        />
        <ul
          id={uid}
          className={classNames(classes.ul, isExpanded && classes.expanded, !isExpanded && classes.hide)}
        >
          {item.items.map(subitem => (
            <Item
              activeItemLink={activeItemLink}
              activeItemParents={activeItemParents}
              item={subitem}
              key={subitem.title}
              location={location}
              onLinkClick={onLinkClick}
              isExpanded={isExpanded}
              onSectionTitleClick={onSectionTitleClick}
              openSectionHash={openSectionHash}
              className={classes.ulStepsUI}
              // styles={{
              //   ...(item.ui === `steps` && {
              //     ...styles.ulStepsUI,
              //   }),
              // }}
              ui={item.ui}
            />
          ))}
        </ul>
      </li>
    )
  }
}

export default withStyles(styles)(Accordion)
