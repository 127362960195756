import React, { FunctionComponent } from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts/IndexLayout'
import StickyResponsiveSidebar from '../components/sidebar/StickyResponsiveSidebar';
import links from '../data/links'
import { withStyles, createStyles, Theme, WithStyles } from '@signatu/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  content: {
    overflow: "scroll"
  }

})

interface IndexPageProps {
  location: Location
}

const IndexPage: FunctionComponent<WithStyles<typeof styles> & IndexPageProps> = ({location, classes}) => (
  <IndexLayout>
    <Page className={classes.root}>
      <StickyResponsiveSidebar
              enableScrollSync={true}
              itemList={links()}
              location={location}
      />
      <Container className={classes.content}>
        <h1>Signatu Documentation</h1>
        <p>
          Welcome to the Signatu technical documentation. Use the sidebar to navigate.
        </p>

        Feel free to contact us at <a href="support@signatu.com">support@signatu.com</a> if you have questions or suggestions.
      </Container>
    </Page>
  </IndexLayout>
)

export default withStyles(styles)(IndexPage)
