import '../styles/normalize';
import 'modern-normalize';

import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { FunctionComponent } from 'react';
import Helmet from 'react-helmet';

import Header from '../components/Header';
import LayoutMain from '../components/LayoutMain';
import LayoutRoot from '../components/LayoutRoot';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
}

const IndexLayout: FunctionComponent = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
        <LayoutRoot>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.description },
              { name: 'keywords', content: 'gdpr, privacy, consent, trackers, privacy policy' }
            ]}
          />
            <Header title={data.site.siteMetadata.title} />
            <LayoutMain>{children}</LayoutMain>
        </LayoutRoot>
    )}
  />
)

export default IndexLayout
