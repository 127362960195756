
import React from "react"
import {GoFold, GoUnfold} from "react-icons/go"
import { Theme, createStyles, withStyles, WithStyles } from "@signatu/styles";

const styles = (theme: Theme) => createStyles({
  icon: {
    display: `inline-block`,
    marginLeft: 8,
  },
  button: {
      // fontSize: scale[0],
      // lineHeight: lineHeights.solid,
      background: `transparent`,
      border: `none`,
      // borderRadius: radii[1],
      color: theme.palette.grey[300],
      display: `flex`,
      cursor: `pointer`,
      alignItems: `center`,
      flexGrow: 0,
      paddingTop: theme.spacing.unit / 4,
      paddingBottom: theme.spacing.unit / 4,
      textAlign: `left`,
      transition: `all 200ms`,
      "&:hover": {
        background: theme.palette.secondary.light
      }
    }
})

interface ExpandAllButtonProps {
  onClick: (...args) => any
  expandAll: boolean
}

const ExpandAllButton: React.FunctionComponent<ExpandAllButtonProps & WithStyles<typeof styles>> = ({ classes, onClick, expandAll }) => (
  <button
    onClick={onClick}
    className={classes.button}
  >
    {expandAll ? (
      <>
        <span>Collapse All</span>
        <span className={classes.icon}>
          <GoFold />
        </span>
      </>
    ) : (
      <>
        <span>Expand All</span>
        <span className={classes.icon}>
          <GoUnfold />
        </span>
      </>
    )}
  </button>
)

export default withStyles(styles)(ExpandAllButton)
